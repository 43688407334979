<template>
    <a-layout id="guest-onboarding-layout-create-org">
        <a-layout-header class="guest-onboarding-header">
            <div class="logo">
                <img src="/partners/sims-logo-new.png" alt="Logo" />
            </div>
        </a-layout-header>
        <a-layout-content :style="{ background: 'rgb(247, 248, 250)', padding: '30px', overflow: 'auto' }">

            <loading-screen :is-loading="isSaving || isLoadingTemplates"></loading-screen>

            <!-- {{ templates }} -->

            <div class="inner">
                <h1 class="intro">Step 2: Create Your Organisation</h1>

                <a-row class="form-row" :gutter="60">
                    <a-col :span="5"></a-col>
                    <a-col :span="14">

                        <a-row :gutter="30">
                            <a-col :span="12">
                                <a-form-item label="Your First Name">
                                    <a-input size="large" v-model="form.first_name"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Your Last Name">
                                    <a-input size="large" v-model="form.last_name"></a-input>
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item label="School Name">
                            <a-input size="large" v-model="form.org_name"></a-input>
                        </a-form-item>

                        <a-form-item label="School Type">
                            <a-radio-group v-model="form.type">
                                <a-radio value="primary">Primary</a-radio>
                                <a-radio value="secondary">Secondary</a-radio>
                                <a-radio value="sen">Special Education Needs</a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item label="Terms and Conditions" class="terms-and-conditions">
                            <a-row>
                                <!-- <a-col :span="12">
                                    <a-checkbox v-model="form.terms">
                                        I accept the terms and conditions
                                    </a-checkbox>
                                </a-col> -->
                                <a-col :span="12">
                                    <a-checkbox v-model="form.privacy">
                                        I accept the <a href="https://teamsos.co.uk/privacy-policy/" target="_blank">privacy
                                            policy</a>
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                            <br>
                        </a-form-item>

                        <a-button class="create-account-btn" size="large" type="primary"
                            @click.prevent="attemptCreateOrganisation">Create
                            Organisation</a-button>

                    </a-col>
                </a-row>

            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import LoadingScreen from '../../components/LoadingScreen.vue';
import onboarding from '../../api/onboarding';
import { mapGetters, mapActions } from 'vuex';
import posthog from 'posthog-js';

export default {
    components: { LoadingScreen },

    data() {
        return {
            isLoadingTemplates: false,
            templates: [],

            isSaving: false,

            form: {
                first_name: '',
                last_name: '',
                org_name: '',
                type: 'primary',
                terms: false,
                privacy: false
            }
        }
    },

    created() {
        // If posthog initialised
        if (window.POSTHOG_INITIALISED) {
            posthog.capture('onboarding_create_org');
        }

        this.loadTemplates();
    },

    computed: {
        ...mapGetters('guestOnboarding', {
            externalIdProvider: 'externalIdProvider',
            externalId: 'externalId'
        }),

        templateId() {
            if (this.form.type == 'primary') {
                // find template that has display name including '_SIMS Primary'
                let template = this.templates.find(t => t.displayName.includes('_SIMS Primary'));
                if (template) {
                    return template.id;
                }
            } else if (this.form.type == 'secondary') {
                // find template that has display name including '_SIMS Primary'
                let template = this.templates.find(t => t.displayName.includes('_SIMS Secondary'));
                if (template) {
                    return template.id;
                }
            } else if (this.form.type == 'sen') {
                // find template that has display name including '_SIMS Primary'
                let template = this.templates.find(t => t.displayName.includes('_SIMS SEN'));
                if (template) {
                    return template.id;
                }
            }
            return null;
        }
    },

    methods: {
        ...mapActions('missionControl', {
            launch: 'launch'
        }),

        validate() {
            if (!this.form.first_name || this.form.first_name.trim().length == 0) {
                this.$message.error('Please enter your first name');
                return false;
            }

            if (!this.form.last_name || this.form.last_name.trim().length == 0) {
                this.$message.error('Please enter your last name');
                return false;
            }

            if (!this.form.org_name || this.form.org_name.trim().length == 0) {
                this.$message.error("Please enter your school's name");
                return false;
            }

            if (!this.form.type) {
                this.$message.error('Please select your school type');
                return false;
            }

            // if (!this.form.terms) {
            //     this.$message.error('Please accept the terms and conditions');
            //     return false;
            // }

            if (!this.form.privacy) {
                this.$message.error('Please accept the privacy policy');
                return false;
            }

            return true;
        },

        loadTemplates() {
            let vm = this;
            vm.isLoadingTemplates = true;
            onboarding.getTemplates().then(r => {
                vm.templates = r.data;
                vm.isLoadingTemplates = false;
            }).catch(e => {
                console.log(e);
                vm.isLoadingTemplates = false;
            });
        },

        attemptCreateOrganisation() {
            if (!this.validate()) {
                return false;
            }

            let vm = this;
            vm.isSaving = true;

            // Generate random external ID 000-0000-RANDOM
            let randomId = '000-0000-' + String(Math.floor(Math.random() * 10000));

            let params = {
                externalIdProvider: vm.externalIdProvider ? vm.externalIdProvider : 'LEADFES',
                externalId: vm.externalId ? vm.externalId : randomId,
                orgName: vm.form.org_name,
                adminFirstName: vm.form.first_name,
                adminSurname: vm.form.last_name,
                applyTemplateId: vm.templateId,
                source: 'SIMS',
                initialStage: 'Technical Setup'
            };

            onboarding.createOrg(params).then(() => {
                // vm.isSaving = false;
                vm.launch();
                vm.$router.push('/onboarding');
            }).catch(e => {
                console.log(e);
                vm.isSaving = false;
                vm.$message.error('An error occurred while creating your organisation. Please try again.');
            });
        }
    }
}
</script>

<style lang="scss">
#guest-onboarding-layout-create-org {
    // min-height: 100vh;
    // background: #3A0BA3;
    background: rgb(247, 248, 250);
    overflow-y: scroll !important;
    height: 100vh;
    max-height: 100vh;


    .guest-onboarding-header {
        background: #3A0BA3;
        text-align: center;

        height: 65px;
        line-height: 65px;

        .logo {
            height: 40px;

            img {
                max-height: 100%;
            }
        }
    }


    .intro {
        text-align: center;
        font-size: 2.3em;
        margin-bottom: 40px;
        // color: #fff;
        font-family: 'Quicksand';
        font-weight: 500;
    }

    .ant-input {
        // background: rgba(255, 255, 255, 0.2);
        // border: none;
        // color: #fff;
        padding: 25px 20px;
        // border-radius: 25px;
    }

    .ant-form-item-label>label {
        // color: #fff !important;
        font-family: 'Quicksand';
        font-weight: 500 !important;
        font-size: 1.1em !important;
    }

    .terms-and-conditions {
        .ant-form-item-control {
            padding-top: 11px;
            margin-bottom: 0;
            padding-bottom: 0;
            line-height: 20px;
        }
    }

    .create-account-btn {
        display: block;
        text-align: center;
        margin-top: 25px;
        // color: #fff;
        // padding: 15px 40px;
        border-radius: 25px;
        font-family: 'Quicksand';
        // border: 2px solid #fff;
        font-weight: 500;
        // transition: all 0.2s ease-in-out;
        // margin-left: 10px;
        // margin-right: 10px;
        width: 100%;

        // &:hover {
        //     color: #3A0BA3;
        //     background: #fff;
        // }
    }

    .ant-checkbox-wrapper span {
        // color: #fff;
    }

    .ant-radio-group span {
        // color: #fff;
    }

    .inner {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        ul {
            padding-left: 17px;
        }

        p,
        li {
            font-size: 16px;
            // color: #fff;
            font-family: 'Quicksand';
        }

        ul li+li {
            margin-top: 5px;
        }
    }
}
</style>